@progress-radius: 4px;
@progress-remaining-color: #f0f0f0;
@progress-default-color: #fd3559;

.ant-progress {
    &-status-success {
        .ant-progress-bg {
            background-color: #1890ff;
        }
    }

    &-status-exception {
        .ant-progress-bg {
            background-color: #ff981f;
        }
    }
}
