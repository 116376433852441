@table-header-cell-split-color: @bg-light;
@table-header-bg: @bg-light;
@table-header-color: @text-color-main;

.ant-table {
    color: @text-color-secondary;

    &-body {
        tr {
            td {
                border-bottom: none;
            }
        }
    }

    tr:nth-child(2n) {
        background-color: @bg-deep-light;
    }

    &-column-sorter,
    &-filter-trigger {
        color: @text-color-main;
    }
}
