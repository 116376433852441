.main {
    border: none;
    background: #f5f5f5;
    color: #666666;
    border-radius: 4px;
    font-size: 16px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;

    &:hover {
        background-color: #1890ff;
        color: #ffffff;
        font-weight: 600;
        transform: scale(1.02);
    }
}

