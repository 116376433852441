.legend {
    display: flex;
    gap: 8px;
    align-items: center;
}

.legend::before {
    content: "";
    display: block;
    width: 24px;
    height: 0px;
    border-radius: 10px;
    border-top-width: 3px;
    border-top-style: dashed;
}

.current::before {
    border-color: #ffb904;
    border-top-style: solid;
}

.withChange::before {
    border-color: #52c41a;
}

.noAction::before {
    border-color: #f03fff;
}
