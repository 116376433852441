@segmented-bg: @bg-light;
@segmented-label-color: @text-color-tertiary;

.ant-segmented {
    border-radius: @border-radius-base;
    padding: 4px;

    &-item {
        font-weight: @text-bold;

        &-selected {
            border-radius: @border-radius-base;
            box-shadow: @box-shadow-base;
            color: @text-color-tertiary;
        }
    }
}
