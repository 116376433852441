@carousel-dot-width: 8px;
@carousel-dot-height: 8px;
@carousel-dot-active-width: 20px;

.ant-carousel {
    .slick-dots {
        li {
            button {
                border-radius: 4px;
                background: #3e5e8e;
                opacity: 1;
            }

            &.slick-active {
                button {
                    background: #1890ff;
                }
            }
        }
        &-bottom {
            bottom: 0;
        }
    }

    .slick-slider:has(.slick-dots-bottom) {
        padding-bottom: 16px;
    }
}
