@import "../../assets/styles/color/index.less";

.NegotiationLeverItem {
    background: @bg-active;
    border-radius: @border-radius-base;
    padding: 4px 10px;
    cursor: pointer;

    &-plus {
        width: 16px;
        height: 16px;
        fill: @fill-color-primary;
        vertical-align: middle;
    }

    &-question {
        color: @fill-color-primary;
    }

    &-name {
        color: @text-color-main;
    }

    &:hover {
        background: @bg-hover;
    }
}

