@select-border-color: @bg-transparent;
@select-background: @bg-light;

.ant-select {
    &:not(.ant-select-disabled):hover {
        .ant-select-selector {
            border-color: @border-hover-color;
            background: @color-white;
        }
    }

    &:not(.ant-select-customize-input) {
        .ant-select-selector {
            border-radius: @border-radius-2x;
        }
    }
    &-focused {
        &:not(.ant-select-disabled) {
            &.ant-select {
                &:not(.ant-select-customize-input) {
                    .ant-select-selector {
                        border-color: @border-hover-color;
                        background: @bg-active;
                        box-shadow: none;
                        color: @text-color-main;
                    }
                }
            }
        }
    }

    .ant-select-selection-placeholder {
        color: @text-color-tertiary;
    }

    .ant-select-arrow {
        color: @select-arrow-color;
    }

    &-single {
        &.ant-select-open {
            .ant-select-selection-item {
                color: @text-color-main;
            }
        }
    }
}
