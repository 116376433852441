.mic {
    &::before {
        content: "";
        position: absolute;
        width: inherit;
        height: inherit;
        border-radius: 50%;
        background-color: #5ad81d;
    }
}

.listening {
    &::before {
        display: block;
        animation: listening 1.3s infinite;
    }
}

.attachment {
    padding-left: 12px;
    text-align: start;
    margin-top: 8px;
}

@keyframes listening {
    from {
        opacity: 0.5;
    }
    to {
        transform: scale(2);
        opacity: 0;
    }
}

.actions {
    margin-top: 16px;
}

