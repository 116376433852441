.mic {
    &::before {
        content: "";
        position: absolute;
        width: inherit;
        height: inherit;
        border-radius: 50%;
        background-color: #5ad81d;
    }
}

.listening {
    &::before {
        display: block;
        animation: listening 1.3s infinite;
    }
}

.answer {
    padding: 12px;
    border-radius: 8px;
    background: #1c2a40;
    text-align: start;
    margin-bottom: 16px;
}

.attachment {
    padding: 12px;

    &Item {
        position: relative;
    }

    &Image {
        border-radius: 4px;
        object-fit: cover;
    }

    &Close {
        color: #d9d9d9;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(50%, -50%);
        cursor: pointer;
    }
}

@keyframes listening {
    from {
        opacity: 0.5;
    }
    to {
        transform: scale(2);
        opacity: 0;
    }
}

