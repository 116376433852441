.ActionDropdown {
    padding: 4px 10px 4px 10px;
    border-radius: 4px;
    background: rgb(230, 243, 255);
    cursor: pointer;

    &-trigger {
        &-arrow {
            transition: all 0.2s;
        }

        &.ant-dropdown-open {
            .ActionDropdown-trigger-arrow {
                transform: rotate(180deg);
            }
        }
    }
}

