.dragger {
    &:global(.ant-upload-drag) {
        border: none;
        background: #f5f5f5;
        border-radius: 4px;
        font-size: 16px;

        :global(.ant-upload-btn) {
            padding: 0;
        }
    }
}

