.GenericModal {
    .ant-modal-content {
        padding: 8px 16px;
        color: #c2c9d6;
        background-color: #141e2e;
        border: 1px solid rgb(62, 94, 142);
        border-radius: 8px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
        .ant-modal-header {
            color: #d9d9d9;
            background-color: #141e2e;
            border-bottom-color: rgb(37, 56, 85);
            padding: 12px 4px;

            .ant-modal-title {
                color: #d9d9d9;
            }
        }

        .ant-modal-close {
            top: 8px;
        }

        .ant-modal-body {
            padding: 24px 4px;
        }

        .ant-modal-footer {
            padding: 16px 4px;
            border-top-color: rgb(37, 56, 85);
        }
    }
}

