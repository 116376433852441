.IntelligenceModal {
    &-content {
        &-welcome {
            font-size: 24px;
        }

        &-overview {
            font-size: 16px;
            margin-top: 16px;
        }

        &-insight {
            padding: 12px;
            border-radius: 8px;
            background: #1c2a40;
            margin-top: 16px;

            &__title {
                color: #1890ff;
                font-weight: 500;
                margin-bottom: 4px;
            }
        }
    }
}

