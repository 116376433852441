.container {
    background: #fafafa;
    border-radius: 8px;
    padding: 24;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #d9d9d9;
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 100%;
}

.title {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.data {
    font-size: 48px;
    font-weight: 700;
    color: #1890ff;
}

