.MatchScore {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 12px 22px;
    height: 100%;
}

.MatchScore__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MatchScore__content-title {
    color: "#000000";
}

.MatchScore__content-score {
    margin-top: 4px;
    font-weight: 700;
}

.MatchScore--success {
    background: #e9fae0;
    border-bottom: 1px solid #5ad81d;
}
.MatchScore--success .MatchScore__content-score {
    color: #4fbd19;
}

.MatchScore--warning {
    background: #ffecdb;
    border-bottom: 1px solid #ff981f;
}
.MatchScore--warning .MatchScore__content-score {
    color: #ff8400;
}

.MatchScore--danger {
    background: #ffe6ea;
    border-bottom: 1px solid #fd6883;
}
.MatchScore--danger .MatchScore__content-score {
    color: #fd3559;
}

.MatchScore--middle .MatchScore__content-title {
    font-size: 14px;
}
.MatchScore--middle .MatchScore__content-score {
    font-size: 40px;
}

.MatchScore--small .MatchScore__content-title {
    font-size: 12px;
}
.MatchScore--small .MatchScore__content-score {
    font-size: 24px;
}
