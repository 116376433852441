@import "~antd/dist/antd.less";
@import "./assets/styles/override/index.less";

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
    background-color: #001529;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.mainCanvaContainer {
    width: 100%;
    /* min-width: 800px; */
    height: 100%;
    /* min-height: 600px; */
}
.attributeGroup {
    padding: 0px;
}
.flexbox2 {
    flex-grow: 1;
    flex-direction: column;
    display: flex;
}
.flexbox {
    flex-grow: 1;
    display: flex;
}

.vendorCardTitle {
    font-weight: bold;
}

.dashboardOverviewHeader {
    font-weight: bolder;
}

.site-tag-plus {
    background: #fff;
    border-style: dashed;
}

.scrollable-notification {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */
}
.scrollable-notification::-webkit-scrollbar {
    /* display: block;  */
    width: 4px;
}
.scrollable-notification::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: gray;
    /* outline: 1px solid slategrey; */
}

.loginButtons {
    width: 281px;
}
.loginRow {
    padding: 2px;
}
.hmToolTipTitle {
    font-weight: bold;
}
.hmToolTipVendor {
    text-decoration: underline;
}
.dashboardLet {
    display: inline-block;
    padding: 4px 2px 2px;
    padding-left: 20px;
}
.dashboardLetTitle {
    color: #00000073;
    padding-right: 5px;
    display: block;
    float: left;
}
.dashboardLetValue {
    float: left;
}
.uploadSummary {
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
}
.reviewCanvasContainer {
    position: relative;
}
.reviewCanvasTracker {
    position: fixed;
    color: blue;
}
.canvasLoadingImage {
    width: 100%;
    height: 100%;
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}
.datePeriodPicker {
    float: right;
    width: 300px;
    height: 32px;
}
.react-gravatar {
    border-radius: 50%;
}
.site-layout img.react-gravatar {
    width: 40px;
    height: 40px;
}
.negotiationLever {
    border-radius: 2px;
    border: 1px solid #f0f0f0;
    padding: 8px 12px;
}
.negotiationLever:hover {
    background: #e6f7ff;
    border-color: #1890ff;
}
.ant-table .newlyAddedRow {
    background: #f7fdf1 !important;
}
.alertMessage__success .ant-message-notice-content {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
    box-shadow: none;
}
.alertMessage__success span.anticon {
    display: none;
}
.leverRangeSlider {
    height: 24px;
    margin-right: 16px;
}

.leverRangeSlider .ant-slider-rail {
    height: 16px;
    border-radius: 8px;
    background: #d9d9d9;
    width: calc(100% + 16px);
}

.leverRangeSlider .ant-slider-track {
    display: none;
}

.leverRangeSlider .ant-slider-handle {
    border: 0;
    margin-top: 1px;
    transform: none !important;
    margin-left: 1px;
}

.leverRangeSlider .ant-slider-handle:focus {
    box-shadow: none;
}

.procurementLeverRangeSlider .ant-slider-rail {
    background: #36cfc9;
}

.procurementLeverRangeSlider:hover .ant-slider-rail {
    background-color: #36cfc9;
    opacity: 0.5;
}

.legalLeverRangeSlider .ant-slider-rail {
    background: #40a9ff;
}

.legalLeverRangeSlider:hover .ant-slider-rail {
    background-color: #40a9ff;
    opacity: 0.5;
}

.fullPanelSpin {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(24, 144, 255, 0.08);
    z-index: 1;
}

.fullPanelSpin div.ant-spin {
    max-height: unset;
}

.tagLikeBox__success {
    border-color: #b7eb8f !important;
    background: #f6ffed;
}

.tagLikeBox__warning {
    border-color: #ffd591 !important;
    background: #fff7e6;
}

.tagLikeBox__error {
    border-color: #ffa39e !important;
    background: #fff1f0;
}

.disabledFormItem {
    opacity: 0.4;
}

.disabledFormItem .ant-input-disabled {
    background: unset;
}

.sectionTitle {
    font-weight: 500;
    font-size: 16px;
}

.sectionSubtitle {
    color: #666;
}

.sidebar .ant-layout-sider-trigger {
    position: sticky;
}

