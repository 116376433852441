.ant-checkbox {
    &-indeterminate {
        .ant-checkbox-inner {
            background: @fill-color-primary;

            &::after {
                width: 10px;
                height: 2px;
                border-radius: 2px;
                background-color: @color-white;
            }
        }
    }
}
