@notification-padding-vertical: 12px;
@notification-padding-horizontal: 12px;

.ant-notification {
    &-notice {
        &-warning {
            background: #fff4eb;
        }
    }
}
