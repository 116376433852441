.NotificationSetting {
    &-trigger {
        padding: 4px 10px 4px 10px;
        border-radius: 4px;
        background: rgb(230, 243, 255);

        &-arrow {
            transition: all 0.2s;
        }

        &.ant-dropdown-open {
            .NotificationDropdown-trigger-arrow {
                transform: rotate(180deg);
            }
        }
    }
}

