.title {
    font-size: 24px;
    font-weight: 700;
    color: #5ad81d;
    line-height: 1;
}

.subtitle {
    font-size: 20px;
    font-weight: 500;
    color: #bfbfbf;
    line-height: 1;
    margin-top: 8px;
}

.section {
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
}

.desc,
.tip {
    font-size: 16px;
    font-weight: 500;
    color: #bfbfbf;
}

.desc {
    margin-bottom: 16px;
}

.tip {
    text-align: start;
}

