.AppHeader {
    padding: 0;
    background: #141e2e;

    &-divider {
        background: #3e5e8e;
        height: 24px;
        margin: 0 4px 0 24px;
    }

    &-avatar {
        border-radius: 0;
    }
}

