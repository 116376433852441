.ant-slider {
    &-rail,
    &-track {
        height: 8px;
        background: #1890ff;
    }

    &-handle {
        margin-top: -3px;
    }
}
